@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add custom classes for Nord theme components */
@layer components {
  /* Buttons */
  .btn-primary {
    @apply bg-nord-frost4 hover:bg-nord-frost3 text-white font-medium py-2 px-4 rounded-md transition-colors;
  }
  
  .btn-secondary {
    @apply bg-nord-polarNight4 dark:bg-nord-snowStorm1 dark:text-nord-polarNight1 
          text-nord-snowStorm3 font-medium py-2 px-4 rounded-md transition-colors 
          hover:bg-nord-polarNight3 dark:hover:bg-nord-snowStorm2;
  }
  
  .btn-danger {
    @apply bg-nord-aurora1 hover:bg-opacity-90 text-white font-medium py-2 px-4 rounded-md transition-colors;
  }

  /* Cards */
  .nord-card {
    @apply bg-nord-snowStorm2 dark:bg-nord-polarNight2 
          border dark:border-nord-polarNight4 border-nord-snowStorm1 
          rounded-lg shadow-md overflow-hidden transition-colors;
  }

  /* Inputs */
  .nord-input {
    @apply bg-nord-snowStorm3 dark:bg-nord-polarNight3 
          border dark:border-nord-polarNight4 border-nord-snowStorm1 
          rounded-md py-2 px-3 focus:outline-none 
          focus:ring-2 focus:ring-nord-frost2 
          dark:text-nord-snowStorm2 text-nord-polarNight1 transition-colors;
  }
}

/* Placeholder styling for inputs */
::placeholder {
  @apply dark:text-nord-snowStorm1 text-nord-polarNight4 opacity-80;
}

/* For older browsers */
input::placeholder,
textarea::placeholder,
select::placeholder {
  @apply dark:text-nord-snowStorm1 text-nord-polarNight4 opacity-80;
}

